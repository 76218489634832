/* LandingPage.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
  }
  
  header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    width: 100%;
  }
  
  header h1 {
    margin: 0;
  }
  
  .content-section {
    padding: 2rem;
  }
  
  footer {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  img {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
  
  @media (max-width: 768px) {
    footer {
      position: static;
    }
  }
  